(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('CourseApplicationController', CourseApplicationController);

    CourseApplicationController.$inject = ['$state', '$location', '$window', '$uibModalInstance', 'coursesList'];

    function CourseApplicationController($state, $location, $window, $uibModalInstance, coursesList) {
        var vm = this;

        vm.$state = $state;
        vm.appForm = {};
        vm.baseUrl = new $window.URL($location.absUrl()).origin + '/#/courses';
        vm.courseDesc = coursesList.find(function (el) {
            return el.id === $state.params.id;
        });

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
