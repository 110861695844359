(function () {
    'use strict';

    angular
        .module('portalApp')
        .directive('worldMap', worldMap);

    worldMap.$inject = ['$window', 'mapConfig'];

    function worldMap($window, mapConfig) {
        // Generates world map (Miller projection) using jQuery Vector Map plugin
        // Usage:
        //  <div data-world-map map-data="vm.mapData">
        // Input structure:
        //  vm.mapData = {
        //  }
        // Creates:
        //  <div data-world-map class="worldMap">
        var directive = {
            link: linkFunc,
            restrict: 'A',
            scope: {
                mapData: '<'
            }
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            element.addClass('worldMap');
            element.attr('id', scope.mapData.mapId);
            angular.element(element).width(mapConfig.mapProps.width);
            angular.element(element).height(mapConfig.mapProps.height);

            scope.$watch('mapData', makeMap, true);

            function makeMap() {
                // console.log('here!');

                // set the map data
                mapConfig.setMapData(angular.copy(scope.mapData));

                // load map configuration
                var mapObject = angular.copy(mapConfig.mapObject);

                angular.element(element).vectorMap(mapObject);
            }
        }
    }

})();
