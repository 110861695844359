(function() {
    'use strict';

    angular.module('portalApp')
        .controller('ProtectionPolicyModalController', ProtectionPolicyModalController);

    ProtectionPolicyModalController.$inject = ['$http', '$uibModalInstance', 'Principal', 'Auth'];

    function ProtectionPolicyModalController ($http, $uibModalInstance, Principal, Auth) {
        var vm = this;

        vm.error = null;
        vm.success = null;

        vm.text = "text is being loaded...";

        vm.cancel = cancel;
        vm.saveAcceptProtectionPolicy = saveAcceptProtectionPolicy;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function saveAcceptProtectionPolicy () {

            Principal.identity().then(function(account) {
                vm.currentAccount = account;
                var date = new Date();
                vm.currentAccount.policyAcceptedDate = date;
                console.log("Updating user with date of protection policy acceptance " + date);
                $http({method: 'PUT', url: 'api/user_acceptance?code=GDPR-GPMN-text'}).
                then(function(response) {
                    console.log('The acceptance saved.');
                }, function(response) {
                    console.error('The acceptance was not saved.');
                });

                $uibModalInstance.dismiss('cancel');
            });
        }
    }
})();
