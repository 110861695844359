(function() {
    'use strict';
    angular
        .module('portalApp')
        .factory('ResourceOpen', ResourceOpen);

    ResourceOpen.$inject = ['$resource'];

    function ResourceOpen ($resource) {
        var resourceUrl =  'api/open/resources/:id';

        return $resource(resourceUrl, {}, {
            'download': {
                method: 'GET',
                responseType: "arraybuffer",
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
