(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('WorkshopsController', WorkshopsController);

    WorkshopsController.$inject = ['$scope', 'LoginService', 'Principal'];

    function WorkshopsController ($scope, LoginService, Principal) {
        var vm = this;

        //***************************************************************
        // basic auth operations
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        //***************************************************************
    }
})();
