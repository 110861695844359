(function () {
    'use strict';

    angular
        .module('portalApp')
        .provider('mapConfig', mapConfig);

    // configuration
    function mapConfig() {
        // all highlighted countries
        const countryList = [
            'US', 'CA', 'AT', 'BE', 'HR', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE',
            'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES', 'GB', 'JP',
            'KR', 'AU', 'CZ', 'DK', 'HK', 'IL', 'NZ', 'NO', 'SG', 'SE', 'CH', 'TW',
            'CN', 'IN', 'ID', 'MY', 'PH', 'TH', 'BR', 'MX', 'CL', 'CO', 'PE', 'RU',
            'TR', 'PL', 'ZA'
        ];

        // list of Eurozone countries
        const ezList = [
            'AT', 'BE', 'HR', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV',
            'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES'
        ];

        // list of RC countries
        const rcList = [
            'CA', 'KR', 'AU', 'CZ', 'DK', 'HK', 'IL', 'NZ', 'NO', 'SG', 'SE', 'CH',
            'TW', 'ID', 'MY', 'PH', 'TH', 'CL', 'CO', 'PE', 'TR', 'PL'
        ];

        var mapData = {};

        this.$get = function () {
            return {
                setMapData: function (data) {
                    mapData = data;
                },
                mapObject: {
                    map: 'world_mill_en',
                    backgroundColor: 'none',
                    regionStyle: {
                        initial: {
                            fill: '#5c6267',
                            "fill-opacity": 1,
                            stroke: '#6e164e',
                            "stroke-width": 0,
                            "stroke-opacity": 1
                        },
                        hover: {
                            fill: '#560939',
                            "fill-opacity": 1,
                            cursor: 'default'
                        }
                    },
                    series: {
                        regions: [{
                            scale: {
                                '0': '#5c6267',
                                '1': '#6e164e'
                            },
                            attribute: 'fill',
                            values: countryList.reduce(function (obj, country) {
                                if (rcList.includes(country)) {
                                    obj[country] = '0';
                                } else {
                                    obj[country] = '1';
                                }
                                return obj;
                            }, {})
                        }]
                    },
                    onRegionTipShow: onRegionTipShow,
                    onRegionOver: onRegionOver
                },
                mapProps: {
                    width: 'auto',
                    height: 500
                }
            };
        };

        function onRegionTipShow(e, el, code) {
            // don't show tooltip for non-forecasted countries
            if (!countryList.includes(code) || rcList.includes(code)) {
                e.preventDefault();
                return;
            }
            const isEz = ezList.includes(code);
            const isRc = rcList.includes(code);
            if (isEz) {
                el.html('<span class="region-tip-title">Eurozone</span></br>');
            } else {
                el.html('<span class="region-tip-title">' + el.html() + '</span></br>');
            }
            const theCode = isEz ? 'EZ' : code;
            const value = (mapData.values && mapData.values[theCode])
                ? mapData.values[theCode]
                : "???";
            const prodStr = "GDP growth: " + value + "%";
            el.html(el.html() + prodStr);
        }

        function onRegionOver(e, code) {
            if (!countryList.includes(code) || rcList.includes(code)) {
                e.preventDefault();
            }
        }
    }
})();
