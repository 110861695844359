(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('CoursesTableController', CoursesTableController);

    CoursesTableController.$inject = ['$state', '$location', '$anchorScroll', '$sce', 'coursesList'];

    function CoursesTableController($state, $location, $anchorScroll, $sce, coursesList) {
        var vm = this;

        vm.$state = $state;
        // pre-process content of coursesList element to make all fields contain expected types
        vm.coursesList = coursesList.map(function (el) {
            // dates and applyBy should be arrays
            if (!angular.isArray(el.dates)) {
                el.dates = angular.isString(el.dates) ? [el.dates] : [];
            }
            if (!angular.isArray(el.applyBy)) {
                el.applyBy = angular.isString(el.applyBy) ? [el.applyBy] : [];
            }
            // adjust course details
            if (el.hasOwnProperty('details') && el.details) {
                // audience, skills, takeaways and topics should be arrays
                if (!angular.isArray(el.details.audience)) {
                    el.details.audience = angular.isString(el.details.audience) ? [el.details.audience] : [];
                }
                if (!angular.isArray(el.details.skills)) {
                    el.details.skills = angular.isString(el.details.skills) ? [el.details.skills] : [];
                }
                if (!angular.isArray(el.details.takeaways)) {
                    el.details.takeaways = angular.isString(el.details.takeaways) ? [el.details.takeaways] : [];
                }
                if (!angular.isArray(el.details.topics)) {
                    el.details.topics = angular.isString(el.details.topics) ? [el.details.topics] : [];
                }
                // description may contain some HTML tags to be evaluated, so trust those
                if (angular.isString(el.details.desc)) {
                    el.details.desc = $sce.trustAsHtml(el.details.desc);
                }
            }
            return el;
        });
        vm.selectedCourse = undefined;

        vm.selectCourse = selectCourse;

        function selectCourse(course) {
            vm.selectedCourse = (angular.isDefined(vm.selectedCourse) && vm.selectedCourse.id === course.id)
                ? undefined
                : angular.copy(course);

            if (angular.isDefined(vm.selectedCourse)) {
                $location.hash('course-description');
                $anchorScroll();
            }
            else {
                $location.hash('');
            }
        }
    }
})();
