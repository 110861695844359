(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['$scope', '$state', 'FooterService'];

    function FooterController ($scope, $state, FooterService) {
        var vm = this;

        vm.applicationVersion = "";

        FooterService.get().$promise.then(function(response) {
            vm.applicationVersion = response.applicationVersion;
        });
    }
})();
