(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('ChartsController', ChartsController);

    ChartsController.$inject = ['$state', 'projChartSeries', 'roundInfo', 'countryResources', 'Resource', 'DataUtils', 'DownloadResource'];

    function ChartsController($state, projChartSeries, roundInfo, countryResources, Resource, DataUtils, DownloadResource) {
        var vm = this;
        vm.$state = $state;
        vm.downloadResource = function(res, isDrive) {
            DownloadResource.downloadResource(res, isDrive);
        };
        vm.loadingContent = true;
        vm.dateFrequency = 4;
        vm.shadowRange = [{
            end: roundInfo.lastHist
        }];
        vm.chartEndDate = new Date(roundInfo.lastHist.getFullYear() + 2, roundInfo.lastHist.getMonth() + 9, 1);
        vm.chartMinDate = new Date(roundInfo.lastHist.getFullYear() - 10, roundInfo.lastHist.getMonth(), 1);
        vm.chart1 = {
            chartId: "Chart01",
            title: "Real GDP Growth (% YoY)",
            lineSeries: []
        };
        // remove all the unnecessary object properties
        vm.chart1.lineSeries.push(projChartSeries[0].map(function (tsData) {
            return {date: tsData.date, value: tsData.value};
        }));

        vm.chart2 = {
            chartId: "Chart02",
            title: "Headline CPI Inflation (% YoY)",
            lineSeries: []
        };
        // remove all the unnecessary object properties
        vm.chart2.lineSeries.push(projChartSeries[1].map(function (tsData) {
            return {date: tsData.date, value: tsData.value};
        }));
        if (vm.$state.params.countryCode !== 'WO'){
            vm.chart3 = {
                chartId: "Chart03",
                title: "Short Interest Rate (% p.a.)",
                lineSeries: []
            };
            // remove all the unnecessary object properties
            vm.chart3.lineSeries.push(projChartSeries[2].map(function (tsData) {
                return {date: tsData.date, value: tsData.value};
            }));
        } else {
            vm.chart3 = {
                chartId: "Chart03",
                title: "Output Gap (% of potential)",
                lineSeries: []
            };
            // remove all the unnecessary object properties
            vm.chart3.lineSeries.push(projChartSeries[3].map(function (tsData) {
                return {date: tsData.date, value: tsData.value};
            }));
        }

        //Link for the country report
        // vm.countryReportsLink = {};
        vm.countryResources = countryResources;
        vm.cCodes = ['WO','US','EZ','CN','JP','GB','RU','BR','MX','IN','ZA','RC'];
        vm.currentCode = vm.$state.params.countryCode;
        vm.reportType = {};
        for (var i=0; i < vm.countryResources.length; i++) {
            if (vm.cCodes[i] === vm.currentCode && countryResources[i].length !== 0) {
                // vm.countryReportsLink = 'resource/' + countryResources[i][0].id + '/drive' + '/download';
                vm.countryReportsID = countryResources[i][0].id;
                if (vm.currentCode === "WO" || vm.currentCode === "EZ" || vm.currentCode === "RC") {
                    vm.reportType = 'Area Report'; }
                else {
                    vm.reportType = 'Country Report';
                }
            }
            if (vm.cCodes[i] === vm.currentCode && countryResources[i].length === 0) {
                // vm.countryReportsLink = 0;
                vm.countryReportsID = 0;
            }
        }

        vm.loadingContent = false;
    }
})();
