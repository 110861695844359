(function () {
    'use strict';

    angular
        .module('portalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('projections', {
            parent: 'app',
            url: '/projections',
            sticky: true,
            dsr: true,
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/portal/projections/projections.html',
                    controller: 'ProjectionsController',
                    controllerAs: 'vm'
                },
                'actual@projections': {
                    templateUrl: 'app/portal/projections/actual.html'
                },
                'archive@projections': {
                    templateUrl: 'app/portal/projections/archive.html'
                },
                'light-archive@projections': {
                    templateUrl: 'app/portal/projections/light-archive.html'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                allRoundsResources: ['$stateParams', '$q', 'allRoundsInfo', 'Resource',
                    function ($stateParams, $q, allRoundsInfo, Resource) {
                        var promises = [];
                        for (var ix = 0; ix < allRoundsInfo.length; ix++) {
                            const thisRoundInfo = allRoundsInfo[ix];
                            promises.push(Resource
                                .query({
                                    roundId: thisRoundInfo.id,
                                    productCode: "FPAS"
                                })
                                .$promise
                                .catch(function () {
                                    return [];
                                }));
                        }
                        return $q.all(promises);
                    }],
                countryResources: ['$q', 'Resource', function($q, Resource){
                    var cRounds = [980, 981, 982, 983, 984, 985, 986, 987, 988, 989, 990, 991];
                    var promResArr = [];
                    for (var ix = 0; ix < cRounds.length; ix++) {
                        const cRound = cRounds[ix];
                        promResArr.push(Resource.query({
                            roundId: cRound,
                            productCode: "FPAS"
                        }).$promise
                            .catch(function () {
                                return [];
                            }))
                    }
                    return $q.all(promResArr);
                }],
                tableSeries: ['$stateParams', '$q', 'TimeseriesData', 'roundInfo', function ($stateParams, $q, TimeseriesData, roundInfo) {
                    var cCodes = ['WO', 'US', 'EZ', 'CN', 'JP', 'GB', 'RU', 'BR', 'MX', 'IN', 'ZA', 'RC'];
                    var promArr = [];
                    var lastFullRoundId = roundInfo.id;
                    for (var ix = 0; ix < cCodes.length; ix++) {
                        const cCode = cCodes[ix];
                        promArr.push(TimeseriesData.query({
                            "roundId": lastFullRoundId,
                            "actionCode": 'scode',
                            "applicationVariable": 'DOT4_GDP_' + cCode,
                            "applicationId": 4
                        }).$promise
                            .catch(function () {
                                return [];
                            }));
                        promArr.push(TimeseriesData.query({
                            "roundId": lastFullRoundId,
                            "actionCode": 'scode',
                            "applicationVariable": 'DOT4_CPI_' + cCode,
                            "applicationId": 4
                        }).$promise
                            .catch(function () {
                                return [];
                            }));
                    }
                    return $q.all(promArr);
                }]
            }
        })
            .state('charts', {
                parent: 'projections',
                url: '',
                params: {
                    countryCode: 'WO'
                },
                views: {
                    'charts': {
                        templateUrl: 'app/portal/projections/charts.html',
                        controller: 'ChartsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    projChartSeries: ['$stateParams', '$q', 'TimeseriesDataMnemonicOpen', function ($stateParams, $q, TimeseriesDataMnemonicOpen) {
                        if ($stateParams.countryCode === null) {
                            return $q.when([]);
                        }
                        var seriesList = [
                            {name: 'D4L_GDP_' + $stateParams.countryCode, prefix: "DOT4"},
                            {name: 'D4L_CPI_' + $stateParams.countryCode, prefix: "DOT4"},
                            {name: 'RS_' + $stateParams.countryCode, prefix: "NC"},
                            {name: 'L_GDP_GAP_' + $stateParams.countryCode, prefix: ""},
                        ];
                        var promArr = [];
                        for (var ix = 0; ix < seriesList.length; ix++) {
                            promArr.push(TimeseriesDataMnemonicOpen.query({
                                "actionCode": 'sm',
                                "baseSeriesName": seriesList[ix].name,
                                "mnemoPrefix": seriesList[ix].prefix,
                                "roundType": 'regular'
                            }).$promise);
                        }
                        return $q.all(promArr);
                    }],
                }
            });
    }
})();

