(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('ProjectionsController', ProjectionsController);

    ProjectionsController.$inject = ['$filter', '$scope', '$state', 'Resource', 'DateUtils', 'DataUtils', 'LoginService', 'Principal',
        'allRoundsInfo', 'allRoundsResources', 'tableSeries', 'DownloadResource'];

    function ProjectionsController($filter, $scope, $state, Resource, DateUtils, DataUtils, LoginService, Principal,
        allRoundsInfo, allRoundsResources, tableSeries, DownloadResource) {
        var vm = this;
        vm.$state = $state;
        vm.hasIrregularRound = false;
        vm.isOpenAct = true;
        vm.isOpenArch = false;
        vm.isOpenLightArch = false;
        vm.mainLinksNumber = 0;
        vm.limitLinksTo = 0;
        vm.linkIdsLastFull = {};
        vm.oldLinkIdsFull = [];
        vm.oldLinkIdsLight = [];
        vm.linkIdsLastLight = {};
        vm.linkIdsLastData = {};
        vm.linkIdForecastComparison = {};
        vm.linkIdForecastComparisonHTML = {};
        vm.downloadResource = function(res, isDrive) {
            DownloadResource.downloadResource(res, isDrive);
        };
        vm.downloadResourceOpen = function(res, isDrive) {
            DownloadResource.downloadResourceOpen(res, isDrive);
        };
        vm.switchTabTo = switchTabTo;
        vm.irisLinksPerRound = {};
        vm.projTables = {};
        vm.tableYears = [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1];
        vm.tableTitles = {
            'GDP': 'Real GDP Growth (%)',
            'CPI': 'CPI Inflation (%)'
        };
        vm.countries = {
            'WO': 'World Economy',
            'US': 'United States',
            'EZ': 'Eurozone',
            'CN': 'China',
            'JP': 'Japan',
            'GB': 'United Kingdom',
            'RU': 'Russia',
            'BR': 'Brazil',
            'MX': 'Mexico',
            'IN': 'India',
            'ZA': 'South Africa',
            'RC': 'Remaining Countries'
        };


        // File source and display names for full round resources
        var extraResourcesFull = [['GPM-PRESENTATION-XLSM', 'PRESENTATION_GRAPHS'], ['GPM-MODEL-REPORT', 'MODEL_REPORT'],
            ['GPM-HISTORICAL-FORECASTS', 'FORECAST_ACCURACY'],
            ['GPM-HISTORICAL-DECOMP', 'HISTORICAL_DECOMPOSITION'], ['GPM-FORECAST-DECOMP', 'FORECAST_DECOMPOSITION'],
            ['GPM-FORECAST-CHANGE-DECOMP', 'FORECAST_CHANGE_DECOMPOSITION']];
        var resourcesTypeFull = [['GPM-PRESENTATION-PDF', 'PRESENTATION'], ['GPM-LIGHT-REPORT-PDF', 'PROJECTION_REPORT'],
            ['GPM-KIT', 'MATLAB_FILES'], ['GPM-DATABASE-ANNUAL', 'DATABASE_YEARLY'], ['GPM-DATABASE', 'DATABASE_QUARTERLY'],
            ['GPM-PARAM-CHANGE-LOG', 'MODEL_CALIBRATION_CHANGES'], ['GPM-COMPARE-DATABASES', 'DATABASE_CHANGES'],
            ['GPM-CUSTOMIZED-MODELS-DATA', 'CUSTOMIZED_MODELS_DATA'],
            ['GPM-MONITORING-REPORTS', 'MONITORING_REPORTS'], ['GPM7-FILES', 'GPM7_FILES'],
            ['GPM-IRIS-LINK', 'IRIS_LINK']].concat(extraResourcesFull);

        // File source and display names and icon type for light round resources
        var resourcesTypeLight = [['GPM-LIGHT-REPORT-PDF', 'GLOBAL_PROJECTIONS_REPORT', 'fa fa-file-pdf-o fa-fw'],
            ['GPM-DATABASE-ANNUAL', 'GLOBAL_PROJECTIONS_DATABASE_YEARLY', 'fa fa-file-text-o fa-fw'],
            ['GPM-DATABASE', 'GLOBAL_PROJECTIONS_DATABASE_QUARTERLY', 'fa fa-file-text-o fa-fw']];

        // File source for data round resources
        var resourcesTypeData = ['GPMPP-DATA'];

        // Please update database ID for GPM7_FILES here!
        var GPM7_FILES_ID = {
            gpm201902: 490,
            gpm201903: 513,
            gpm201904: 540,
            gpm201905: 563,
            gpm201906: 617,
            gpm201908: 620,
            gpm201909: 645
        };

        var allDataRoundsData, allLightRoundsData, allFullRoundsData, allLightRoundsInfo, allFullRoundsInfo;

        // Separate light and full rounds, then get last full and light round.
        // However as light rounds are new, the light round resources in the
        // past (before Feb 2019) will be among full rounds
        if (angular.isArray(allRoundsInfo)) {
            allLightRoundsInfo = allRoundsInfo.filter(function (item) {
                if (angular.isObject(item)) {
                    return item.roundCode.includes('light');
                } else {
                    return false;
                }
            });
            allFullRoundsInfo = allRoundsInfo.filter(function (item) {
                if (angular.isObject(item)) {
                    return !(item.roundCode.includes('light') | item.roundCode.includes('data'));
                } else {
                    return false;
                }
            });
        }
        if (angular.isArray(allRoundsResources)) {
            allDataRoundsData = allRoundsResources.filter(function (item) {
                if (angular.isObject(item[0])) {
                    return item[0].description.includes('_data');
                } else {
                    return false;
                }
            });
            allLightRoundsData = allRoundsResources.filter(function (item) {
                if (angular.isObject(item[0])) {
                    return item[0].description.includes('_light');
                } else {
                    return false;
                }
            });
            allFullRoundsData = allRoundsResources.filter(function (item) {
                if (angular.isObject(item[0])) {
                    return !(item[0].description.includes('_light') | item[0].description.includes('_data'));
                } else {
                    return false;
                }
            });
        }

        // LinkIds for all full rounds
        var linkIdsFull = [];
        for (var j = 0; j < allFullRoundsData.length; j++) {
            // Skip all the rounds before March 2019 in the database as they are already populated in archive.html
            if (DateUtils.convertLocalDateFromServer(allFullRoundsInfo[j].created) < new Date(2019, 2, 1)) {
                continue;
            }
            var linkIdsFullTmp = [];
            for (var i = 0; i < resourcesTypeFull.length; i++) {
                var linkIdFullTmp = {};
                var res = allFullRoundsData[j].filter(function (item) {
                    return (item.resourceKeyName === resourcesTypeFull[i][0]);
                })[0];
                var isExtraRes = (j === 0 && !extraResourcesFull.map(function (el) {
                    return el[0];
                }).includes(resourcesTypeFull[i][0]));
                if (angular.isDefined(res)) {
                    // check if this is IRIS link and if so append the iris link dictionary
                    // instead of creating a new linkId
                    if (res['resourceKeyName'] === "GPM-IRIS-LINK") {
                        vm.irisLinksPerRound[allFullRoundsInfo[j].roundCode] = res['url'];
                        continue;
                    }
                    linkIdFullTmp.id = res['id'];
                    linkIdFullTmp.isDrive = false;
                    linkIdFullTmp.link = 'resource/' + res['id'] + '/download';
                    linkIdFullTmp.type = resourcesTypeFull[i][0];
                    linkIdFullTmp.title = resourcesTypeFull[i][1];
                    linkIdFullTmp.roundCode = allFullRoundsInfo[j].roundCode;
                    linkIdFullTmp.pubMonth = $filter('date')(allFullRoundsInfo[j].created, 'MMMM yyyy');
                    linkIdsFullTmp.push(linkIdFullTmp);
                    if (isExtraRes) {
                        vm.mainLinksNumber++;
                    }
                } else if (resourcesTypeFull[i][0] === 'GPM7-FILES' && angular.isDefined(GPM7_FILES_ID[allFullRoundsInfo[j].roundCode])) {
                    // GPM7 is manual and file id list is extended above
                    linkIdFullTmp.id = GPM7_FILES_ID[allFullRoundsInfo[j].roundCode];
                    linkIdFullTmp.isDrive = true;
                    linkIdFullTmp.link = 'resource/' + GPM7_FILES_ID[allFullRoundsInfo[j].roundCode] + '/drive/download';
                    linkIdFullTmp.type = resourcesTypeFull[i][0];
                    linkIdFullTmp.title = resourcesTypeFull[i][1];
                    linkIdFullTmp.roundCode = allFullRoundsInfo[j].roundCode;
                    linkIdFullTmp.pubMonth = $filter('date')(allFullRoundsInfo[j].created, 'MMMM yyyy');
                    linkIdsFullTmp.push(linkIdFullTmp);
                    if (isExtraRes) {
                        vm.mainLinksNumber++;
                    }
                }
            }
            linkIdsFull.push(linkIdsFullTmp);
        }

        // Last full round and all the other full rounds
        if (linkIdsFull.length > 0) {
            vm.linkIdsLastFull = linkIdsFull[0];
            vm.oldLinkIdsFull = linkIdsFull.slice(1);
            vm.limitLinksTo = angular.copy(vm.mainLinksNumber);
        }

        // LinkIds for all light rounds
        var linkIdsLight = [];
        if (angular.isArray(allLightRoundsData) && allLightRoundsData.length > 0) {
            for (var k = 0; k < allLightRoundsData.length; k++) {
                var lightRoundData = allLightRoundsData[k];
                var linkIdsLightTmp = {resources: [], pubMonth: ''};
                for (i = 0; i < resourcesTypeLight.length; i++) {
                    var linkIdLightTmp = {};
                    res = lightRoundData.filter(function (item) {
                        return (item.resourceKeyName === resourcesTypeLight[i][0]);
                    })[0];
                    if (angular.isDefined(res)) {
                        linkIdLightTmp.id = res['id'];
                        linkIdLightTmp.isDrive = false;
                        linkIdLightTmp.link = 'resource/' + res['id'] + '/download';
                        linkIdLightTmp.type = resourcesTypeLight[i][0];
                        linkIdLightTmp.title = resourcesTypeLight[i][1];
                        linkIdLightTmp.icon = resourcesTypeLight[i][2];
                        linkIdLightTmp.roundCode = allLightRoundsInfo[k].roundCode;
                        linkIdsLightTmp.resources.push(linkIdLightTmp);
                        linkIdsLightTmp.pubMonth = $filter('date')(allLightRoundsInfo[k].created, 'MMMM yyyy');
                    }
                }
                linkIdsLight.push(linkIdsLightTmp);
            }
        }

        // Last light round and all the other light rounds
        if (linkIdsLight.length > 0) {
            vm.linkIdsLastLight = linkIdsLight[0];
            vm.oldLinkIdsLight = linkIdsLight.slice(1);
        }

        // LinkIds for last data round
        if (angular.isArray(allDataRoundsData) && allDataRoundsData.length > 0) {
            var lastDataRoundData = allDataRoundsData[0];
            res = lastDataRoundData[0];
            var linkIdDataTmp = {};
            if (angular.isDefined(res)) {
                linkIdDataTmp.id = res['id'];
                linkIdDataTmp.isDrive = false;
                linkIdDataTmp.link = 'resource/' + res['id'] + '/download';
                linkIdDataTmp.type = resourcesTypeData[0];
                linkIdDataTmp.title = res.filename;
            }
            vm.linkIdsLastData = linkIdDataTmp;
        }

        // LinkId for the Forecast Comparison Report of the last full round
        if (angular.isArray(allFullRoundsData) && allFullRoundsData.length > 0) {
            const lastFullRoundData = allFullRoundsData[0];
            res = lastFullRoundData.filter(function (item) {
                return (item.resourceKeyName === 'GPM-FORECAST-COMPARE');
            })[0];
            if (angular.isDefined(res)) {
                vm.linkIdForecastComparison.id = res['id'];
                vm.linkIdForecastComparison.isDrive = false;
                vm.linkIdForecastComparison.link = 'resource/' + res['id'] + '/download';
                vm.linkIdForecastComparison.type = 'GPM-FORECAST-COMPARE';
                vm.linkIdForecastComparison.title = 'Forecast Report (PDF)';
            }
        }

        // LinkId for the Forecast Comparison Report of the last full round
        if (angular.isArray(allFullRoundsData) && allFullRoundsData.length > 0) {
            const lastFullRoundData = allFullRoundsData[0];
            res = lastFullRoundData.filter(function (item) {
                return (item.resourceKeyName === 'GPM-FORECAST-COMPARE-HTML');
            })[0];
            if (angular.isDefined(res)) {
                vm.linkIdForecastComparisonHTML.id = res['id'];
                vm.linkIdForecastComparisonHTML.isDrive = false;
                vm.linkIdForecastComparisonHTML.link = 'resource/' + res['id'] + '/download';
                vm.linkIdForecastComparisonHTML.type = 'GPM-FORECAST-COMPARE-HTML';
                vm.linkIdForecastComparisonHTML.title = 'Forecast Report (HTML)';
            }
        }

        // fill in vm.projTables from tableSeries
        for (i = 0; i < tableSeries.length; i++) {
            if (tableSeries[i][0] && tableSeries[i][0].timeseriesName) {
                const name = tableSeries[i][0].timeseriesName;
                const country = name.substring(name.length - 2);
                const varType = name.substring(5, name.length - 3);
                if (!vm.projTables[varType]) {
                    vm.projTables[varType] = {};
                }
                vm.projTables[varType][country] = getValueForYears(tableSeries[i], vm.tableYears);
            }
        }

        // function downloadResource(res, isDrive) {
        //     Resource
        //         .download({id: res.id, download: true, drive: isDrive})
        //         .$promise
        //         .then(function (response) {
        //             var fileNameIndex = response.headers["content-disposition"].indexOf("filename=");
        //             var fileName = "unknown_file";
        //             if (fileNameIndex > -1) {
        //                 fileName = response.headers["content-disposition"].substr(fileNameIndex + "filename=".length);
        //             }
        //             DataUtils.saveFile(response.headers["content-type"],
        //                 response.data, fileName);
        //         });
        // }

        function switchTabTo(countryCode) {
            $state.go('charts', {countryCode: countryCode});
        }

        function getValueForYears(series, years) {
            if (!angular.isArray(years)) {
                years = [years];
            }
            const ySeries = series.filter(function (d) {
                return years.includes(+d.period.substring(0, 4));
            });
            // return values rounded to `n` decimal places
            const n = 1;
            const exp = Math.pow(10, n);
            var vals = {};
            for (var j = 0; j < ySeries.length; j++) {
                const ix = years.indexOf(+ySeries[j].period.substring(0, 4));
                vals[years[ix]] = Math.round(ySeries[j].value * exp) / exp;
            }
            return vals;
        }

        //***************************************************************
        // basic auth operations
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        $scope.$on('authenticationSuccess', function () {
            getAccount();
            $state.reload();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        //***************************************************************
        // if (!vm.$state.includes('charts')) {
        $state.go('charts', {countryCode: 'WO'});
        // }
    }
})();


