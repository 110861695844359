(function () {
    'use strict';
    angular
        .module('portalApp')
        .factory('DownloadResource', ['$q', 'Resource', 'ResourceOpen', 'DataUtils', function($q, Resource, ResourceOpen, DataUtils) {
            return {
                downloadResource: function(res, isDrive) {
                    return Resource.download({id: res.id, download: true, drive: isDrive}).$promise
                        .then(function(response) {
                            var fileNameIndex = response.headers["content-disposition"].indexOf("filename=");
                            var fileName = "unknown_file";
                            if (fileNameIndex > -1) {
                                fileName = response.headers["content-disposition"].substr(fileNameIndex + "filename=".length);
                            }
                            DataUtils.saveFile(response.headers["content-type"], response.data, fileName);
                        });
                },
                downloadResourceOpen: function(res, isDrive) {
                    return ResourceOpen.download({id: res.id, download: true, drive: isDrive}).$promise
                        .then(function(response) {
                            var fileNameIndex = response.headers["content-disposition"].indexOf("filename=");
                            var fileName = "unknown_file";
                            if (fileNameIndex > -1) {
                                fileName = response.headers["content-disposition"].substr(fileNameIndex + "filename=".length);
                            }
                            DataUtils.saveFile(response.headers["content-type"], response.data, fileName);
                        });
                }
            };
        }]);
})();
