(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$scope', '$state'];

    function ContactController ($scope, $state) {
        var vm = this;


    }
})();
