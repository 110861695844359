(function () {
    'use strict';

    angular
        .module('portalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                homeMapSeries: ['$q', 'roundInfo', 'TimeseriesDataOpen', function ($q, roundInfo, TimeseriesDataOpen) {
                    const lastFullRoundId = roundInfo.id;
                    const cCodes = ['US', 'EZ', 'CN', 'JP', 'GB', 'RU', 'BR', 'MX', 'IN', 'ZA'];
                    var promArr = [];
                    for (var ix = 0; ix < cCodes.length; ix++) {
                        const cCode = cCodes[ix];
                        promArr.push(TimeseriesDataOpen.query({
                            "roundId": lastFullRoundId,
                            "actionCode": 'scode',
                            "applicationVariable": 'DOT4_GDP_' + cCode,
                            "applicationId": 4
                        }).$promise);
                    }
                    return $q.all(promArr);
                }]
            }
        });
    }
})();
