(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('CoursesController', CoursesController);

    CoursesController.$inject = ['$state'];

    function CoursesController ($state) {
        var vm = this;
        vm.$state = $state;

    }
})();
