(function() {
    'use strict';

    angular
        .module('portalApp')
        .constant('chartConstants', {
            'lineColorSet': [
                '#6e164e',
                '#5CB85C',
                '#D9534F',
                '#BB5ED9',
                '#3FD0D9',
                '#D9780B'
            ]
        });
})();
