(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('AboutController', AboutController);

    AboutController.$inject = ['$scope', '$state'];

    function AboutController ($scope, $state) {
        var vm = this;


    }
})();
