(function() {
    'use strict';
    angular
        .module('portalApp')
        .factory('GithubIrisRelease', GithubIrisRelease);

    GithubIrisRelease.$inject = ['$resource'];

    function GithubIrisRelease ($resource, DateUtils) {
        var resourceUrl =  'https://api.github.com/repos/IRIS-Solutions-Team/IRIS-Toolbox/releases/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            }
        });
    }
})();
