(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('IrisController', IrisController);

    IrisController.$inject = ['$scope', '$state', 'LoginService', 'Principal'];//,'irisRelease'

    function IrisController ($scope, $state, LoginService, Principal) { //, irisRelease
        var vm = this;
        vm.irisRelease = {};//irisRelease;

        // ***************************************************************
        // basic auth operations
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        //***************************************************************

    }
})();
