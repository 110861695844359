(function() {
    'use strict';
    angular
        .module('portalApp')
        .factory('GpmRoundOpen', GpmRoundOpen);

    GpmRoundOpen.$inject = ['$resource', 'DateUtils'];

    function GpmRoundOpen ($resource, DateUtils) {
        var resourceUrl =  'api/open/round-info/gpm';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastHist = DateUtils.convertLocalDateFromServer(data.lastHist);
                        data.lastFore = DateUtils.convertLocalDateFromServer(data.lastFore);
                    }
                    return data;
                }
            }
        });
    }
})();
