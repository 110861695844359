(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$http', '$scope', '$state', '$uibModal', 'homeMapSeries'];

    function HomeController($http, $scope, $state, $uibModal, homeMapSeries) {
        var vm = this;

        const currentYear = new Date().getFullYear();

        var values = {};
        if (angular.isArray(homeMapSeries)) {
            for (var i = 0; i < homeMapSeries.length; i++) {
                const series = homeMapSeries[i];
                const name = series[0].timeseriesName;
                const country = name.substring(name.length - 2);
                values[country] = getAnnualValue(series, currentYear);
            }
        }

        vm.loadingContent = true;
        vm.mapData = {
            mapId: "the-map",
            values: values
        };
        vm.loadingContent = false;

        function getAnnualValue(series, year) {
            const val = series
                .filter(function (d) {
                    return d.period.startsWith(year);
                })
                .map(function (d) {
                    return d.value;
                });
            // return rounded to `n` decimal places
            if (val.length > 0 && val[0] !== undefined) {
                const n = 1;
                const exp = Math.pow(10, n);
                return Math.round(val[0] * exp) / exp;
            } else return null;
        }

    }
})();
